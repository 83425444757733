export enum FilterType {
  Single = 'SINGLE',
  Multiple = 'MULTI',
  Boolean = 'BOOLEAN',
  Hierarchy = 'HIERARCHY',
  ScoreBool = 'SCORE_BOOL',
}

export enum FilterOptionColor {
  Info = 'INFO',
  Success = 'SUCCESS',
  Warning = 'WARNING',
}

export enum FilterColor {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
}
